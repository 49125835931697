import React, { useState } from "react";
import Container from "../../components/Container/Container";
import Layout from "../../components/Layout/Layout";
import Seo from "../../components/Seo/Seo";
import SmallHero from "../../components/SmallHero/SmallHero";
import FilterSummary from "../../components/Filters/FilterSummary/FilterSummary";
import FilterSidebar from "../../components/Filters/FilterSidebar/FilterSidebar";
import CardGrid from "../../components/CardGrid/CardGrid";
import ContentImageLeft from "../../components/ContentImageLeft/ContentImageLeft"
import G from "../../images/groenhout_g.png"
import { useWoningenQuery } from "../../hooks/useWoningenQuery";
import { motion } from "framer-motion";
import SmallHeroNew from "../../components/SmallHeroNew/SmallHeroNew";
import { graphql, useStaticQuery } from "gatsby";

const VerkochtPage = () => {

    const data = useWoningenQuery()

    const [maxValue, setMaxValue] = useState('')
    const [minValue, setMinValue] = useState('')
    const [minOpp, setMinOpp] = useState('')
    const [maxOpp, setMaxOpp] = useState('')
    const [type, setType] = useState('')
    const [plaats, setPlaats] = useState([])
    const [slaapkamers, setSlaapkamers] = useState('')
    const [count, setCount] = useState()

    const onChangeHandler = (event) => {
        if (event.target.name === 'max') {
            setMaxValue(event.target.value)
        } else if (event.target.name === 'min') {
            setMinValue(event.target.value)
        } else if (event.target.name === 'type') {
            setType(event.target.value)
        } else if (event.target.name === 'oppMax') {
            setMaxOpp(event.target.value)
        } else if (event.target.name === 'oppMin') {
            setMinOpp(event.target.value)
        } else if (event.target.name === 'plaats') {
            const plaatsArr = plaats;
            plaatsArr.includes(event.target.value) ? setPlaats(plaatsArr.filter((e) => {
                return e !== event.target.value
            })) : setPlaats([...plaats, event.target.value])
        } else if (event.target.name === 'slaapkamers') {
            setSlaapkamers(event.target.value)
        } else if (event.target.name === 'remove') {
            setMaxValue('')
            setMinValue('')
            setType('')
            setMaxOpp('')
            setMinOpp('')
            setPlaats([])
            setSlaapkamers('')
        } else if (event.currentTarget.name === 'remove_single') {
            if (event.currentTarget.value === 'minBedrag') {
                setMinValue('')
            } else if (event.currentTarget.value === 'maxBedrag') {
                setMaxValue('')
            } else if (event.currentTarget.value === 'type') {
                setType('')
            } else if (event.currentTarget.value === 'minOpp') {
                setMinOpp('')
            } else if (event.currentTarget.value === 'maxOpp') {
                setMaxOpp('')
            } else if (event.currentTarget.value === 'Slaapkamers') {
                setSlaapkamers('')
            } else if (event.currentTarget.value === 'Plaatsen') {
                setPlaats([])
            }
        }
    }

    const postCount = (data) => {
        setCount(data)
    }

    const filterSold = data.allWpWoning.nodes.filter((e) => {
        if (e.hqStatus === 'Verkocht') {
            return true
        }
        else { return false }
    })

    const img = filterSold[0]?.featuredImage?.node?.mediaItemUrl

    const seo = useStaticQuery(
        graphql`
        query VerkochtPage {
            wpPage(slug: {eq: "verkocht"}) {
                seo{
                    metaDesc
                    title
                  }
                  content
                featuredImage {
                  node {
                    mediaItemUrl
                  }
                }
              }
        } `
    )



    return (
        <div>
            <Seo title={seo.wpPage.seo.title} description={seo.wpPage.seo.metaDesc}></Seo>
            <SmallHeroNew title={'Ons aanbod'} overlay={true} overlayImage={G} image={img !== undefined ? img : ''}></SmallHeroNew>
            <Container styles="w-full max-w-none my-16 md:px-16 px-4">
                <FilterSummary count={count} changeHandler={onChangeHandler} type={type} minVal={minValue} maxVal={maxValue} minOpp={minOpp} maxOpp={maxOpp} plaats={plaats} slaapkamers={slaapkamers}></FilterSummary>
                <Container styles={'w-full max-w-none flex flex-row justify-between lg:mt-20 mt-4 aanbod__container'}>
                    <Container styles={'flex lg:flex-row flex-col justify-between'}>
                        {/* <FilterSidebar selectedPlaats={plaats} type={type} oppMax={maxOpp} oppMin={minOpp} slaapkamers={slaapkamers} min={minValue} max={maxValue} data={data} changeHandler={onChangeHandler}></FilterSidebar> */}
                        <CardGrid count={postCount} data={filterSold} seller={data.allWpVerkopendeMakelaar} type={type} minVal={minValue} maxVal={maxValue} minOpp={minOpp} maxOpp={maxOpp} plaats={plaats} slaapkamers={slaapkamers}></CardGrid>
                    </Container>
                </Container>
                {/* <ContentImageLeft bg={true}></ContentImageLeft> */}
            </Container>
            <div className="text-content">
                <Container>
                    <div dangerouslySetInnerHTML={{ __html: seo.wpPage.content }}></div>
                </Container>
            </div>
        </div>
    )
}

export default VerkochtPage;